import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"

import logoLight from "../images/rawa-logo.png"
import services from "../data/services"

const Header = () => {
  const [scroll, setScroll] = useState(false)

  const handleScroll = e => {
    setScroll(window.scrollY > 20)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <header id="header">
      <div className="d-none d-lg-block" id="top-bar">
        <div className="container d-flex py-2">
          <div className="nav-item">
            <a href="tel:5025356570" className="nav-link">
              <i className="fas fa-phone-alt mr-2"></i>(502) 535-6570
            </a>
          </div>
          <div className="nav-item mr-auto">
            <a href="mailto:randal@rawiedemann.com" className="nav-link">
              <i className="fas fa-envelope mr-2"></i>randal@rawiedemann.com
            </a>
          </div>
          <div className="nav-item social">
            <a
              href="https://www.facebook.com/RA-Wiedemann-Associates-Inc-164587860255215"
              target="_blank"
              rel="noreferrer"
              className="nav-link"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
          </div>
          <div className="nav-item social">
            <a
              href="https://www.youtube.com/channel/UCEyV13IOKjQdLmK6TSTnzNg"
              target="_blank"
              rel="noreferrer"
              className="nav-link"
            >
              <i className="fab fa-youtube"></i>
            </a>
          </div>

          <div className="nav-item">
            <a href="https://apps.rawiedemann.com" className="nav-link">
              Login
            </a>
          </div>
        </div>
      </div>

      <Navbar
        className={scroll ? "navbar-scrolled" : "py-3"}
        id="mainNav"
        expand="lg"
        fixed="top"
        variant="dark"
      >
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img style={{ maxWidth: 200 }} src={logoLight} alt="RAWA" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="" id="basic-navbar-nav">
            <Nav className="navbar-nav ml-auto my-2 my-lg-0">
              <li className="nav-item">
                <Link to="/" className="nav-link ">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/news" className="nav-link ">
                  News
                </Link>
              </li>
              <li className="nav-item">
                <NavDropdown title="Services" id="basic-nav-dropdown">
                  {services.map((service, index) => (
                    <Link
                      key={index}
                      className="dropdown-item"
                      to={service.link}
                    >
                      {service.name}
                    </Link>
                  ))}
                </NavDropdown>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link ">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link ">
                  Contact
                </Link>
              </li>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  )
}

export default Header
