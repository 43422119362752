import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "rawa-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      bgImage: file(relativePath: { eq: "bg-footer.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <footer>
      <BackgroundImage
        id="footer"
        className="odd footer offers"
        Tag="section"
        fluid={data.bgImage.childImageSharp.fluid}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3 footer-left">
              <div className="d-none d-lg-block mr-4">
                <Img fluid={data.logoImage.childImageSharp.fluid} alt="logo" />
              </div>
              <div className="d-block d-lg-none w-50 mx-auto">
                <Img fluid={data.logoImage.childImageSharp.fluid} alt="logo" />
              </div>
              <p>A leading edge firm specializing in aviation planning</p>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a href="tel:5025356570" className="nav-link light-hover">
                    <i className="fas fa-phone-alt mr-2"></i>
                    (502) 535-6570
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="mailto:randal@rawiedemann.com"
                    className="nav-link light-hover"
                  >
                    <i className="fas fa-envelope mr-2"></i>
                    randal@rawiedemann.com
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://apps.rawiedemann.com"
                    className="nav-link light-hover"
                  >
                    <i className="fas fa-user mr-2"></i>
                    Login
                  </a>
                </li>
                <li className="nav-item">
                  <Link
                    to="/contact"
                    className="mt-4 btn outline-button smooth-anchor"
                  >
                    GET IN TOUCH
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-9 p-0 footer-right">
              <div className="row items">
                <div className="col-12 col-lg-4 item">
                  <div className="card no-move">
                    <h4>About Us</h4>

                    <Link to="/team/randal-wiedemann">
                      <i className="fas fa-chevron-right"></i>Randal Wiedemann
                    </Link>
                    <Link to="/team/rob-barber">
                      <i className="fas fa-chevron-right"></i>Rob Barber III
                    </Link>
                    <Link to="/team/dr-geoffrey-j-d-hewings">
                      <i className="fas fa-chevron-right"></i>Dr. Geoffrey J.D.
                      Hewings
                    </Link>
                    <Link to="/team/paul-wiedemann">
                      <i className="fas fa-chevron-right"></i>Paul Wiedemann
                    </Link>
                    <Link to="/team/seth-wiedemann">
                      <i className="fas fa-chevron-right"></i>Seth Wiedemann
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-lg-4 item">
                  <div className="card no-move">
                    <h4>Key Services</h4>
                    <Link to="/strategic-business-plans">
                      <i className="fas fa-chevron-right"></i>Strategic Business
                      Plans
                    </Link>
                    <Link to="/economic-impact">
                      <i className="fas fa-chevron-right"></i>Economic Impact
                    </Link>
                    <Link to="/airport-marketing">
                      <i className="fas fa-chevron-right"></i>Airport Marketing
                    </Link>
                    <Link to="/video-production">
                      <i className="fas fa-chevron-right"></i>Video Production
                    </Link>
                    <Link to="/public-programs">
                      <i className="fas fa-chevron-right"></i>Public Programs
                    </Link>
                    <Link to="/airport-master-plans">
                      <i className="fas fa-chevron-right"></i>Airport Master
                      Plans
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-lg-4 item">
                  <div className="card no-move">
                    <h4>Technology Solutions</h4>
                    <a
                      href="https://apps.rawiedemann.com/hangar-feasibility"
                      rel="noreferrer"
                    >
                      <i className="fas fa-chevron-right"></i>Business Planning
                    </a>
                    <a
                      href="https://apps.rawiedemann.com/hangar-feasibility"
                      rel="noreferrer"
                    >
                      <i className="fas fa-chevron-right"></i>Hangar Feasibility
                    </a>
                    <a
                      href="https://apps.rawiedemann.com/hangar-feasibility"
                      rel="noreferrer"
                    >
                      <i className="fas fa-chevron-right"></i>Knowledge
                      Retention
                    </a>
                    <a
                      href="https://apps.rawiedemann.com/hangar-feasibility"
                      rel="noreferrer"
                    >
                      <i className="fas fa-chevron-right"></i>Market Analysis
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <section id="copyright" className="p-3 odd copyright">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 p-3 text-center text-lg-left">
              <p>
                © {new Date().getFullYear()} R.A. Wiedemann &amp; Associates,
                Inc.
              </p>
            </div>
            <div className="col-12 col-md-6 p-3 text-center text-lg-right">
              <Link className="text-lg-right" to="/legal/privacy-policy">
                Privacy Policy
              </Link>{" "}
              <b>·</b>{" "}
              <Link to="/legal/terms-and-conditions">
                Terms &amp; Conditions
              </Link>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default Footer
