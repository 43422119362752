const services = [
  {
    name: "Strategic Business Plans",
    description:
      "Designed to improve an airport’s financial performance, economic development, and operation.",
    icon: "far text-primary fa-chart-bar fa-3x",
    link: "/strategic-business-plans",
  },
  {
    name: "Economic Impact",
    description:
      "Examines the direct, indirect, and induced impacts associated with airport development and improvement.",
    icon: "fas text-primary fa-chart-pie fa-3x",
    link: "/economic-impact",
  },
  {
    name: "Airport Marketing",
    description:
      "Effectively communicate the merits of your aviation products, services and vision.",
    icon: "fas text-primary fa-chart-area fa-3x",
    link: "/airport-marketing",
  },
  {
    name: "Video Production",
    description:
      "Broadcast results of a study, spearhead a public participation program, or market an airport or service.",
    icon: "fas text-primary fa-video fa-3x",
    link: "/video-production",
  },
  {
    name: "Public Programs",
    description:
      "Communicate and educate the general public as well as state and local decision makers the benefits of your airport.",
    icon: "fas text-primary fa-users fa-3x",
    link: "/public-programs",
  },
  {
    name: "Air Service Studies",
    description:
      "A detailed examination of the required quality of present and future air carrier service.",
    icon: "fas text-primary fa-plane-departure fa-3x",
    link: "/air-service-studies",
  },
  {
    name: "Airport Master Plans",
    description:
      "Designed to improve an airport’s financial performance, economic development, and operation.",
    icon: "fas text-primary fa-drafting-compass fa-3x",
    link: "/airport-master-plans",
  },
  {
    name: "Environmental Studies",
    description:
      "Ensure that the National Environmental Policy Act (NEPA) is uniformly administered at eligible airports.",
    icon: "fas text-primary fa-hard-hat fa-3x",
    link: "/environmental-studies",
  },
  {
    name: "System Planning",
    description:
      "Define the number and mix of airport types to meet forecast air service needs of a community, region or state.",
    icon: "fas text-primary fa-map-marked-alt fa-3x",
    link: "/system-planning",
  },
]

export default services
